import { Controller } from "stimulus"

export default class extends Controller {
    connect() {}

    uploadTrigger(){
        var multiUploadInput = document.getElementById("upload-input");
        multiUploadInput.click(); // this will trigger the click event
    }
    displayChangedFile(event){
        var changedFileInput = document.getElementById("upload-input");
        var divChangedFiletext = document.getElementById("upload-input-text");
        var changedFile = changedFileInput.files;

        var fileBuffer = new DataTransfer();
        if(changedFile.length!=0){
            Object.keys(changedFile).forEach(function (key, index) {
                let file = changedFile[key];
                fileBuffer.items.add(file);
            })
            changedFileInput.files = fileBuffer.files;

            if (changedFileInput.files.length>0) {
                divChangedFiletext.value=changedFileInput.files[0].name
            }
            else{
                divChangedFiletext.value=''
            }
        }
    }
    
    removeChangedFile(event){
        var divChangedFiletext = document.getElementById("upload-input-text");

        divChangedFiletext.value=null
        document.getElementById("upload-input").value = null;
        
    }

    showSchedule(event){
      const internationalScoutScheduleId = event.target.getAttribute("internationalScoutScheduleId")

      if ($(`#international-scout-schedule-${internationalScoutScheduleId}`).is(":visible")==true){
        $(`#international-scout-schedule-${internationalScoutScheduleId}`).fadeOut()
        $(`#international-scout-schedule-${internationalScoutScheduleId}`).hide() 
      }
      else{
        $(`#international-scout-schedule-${internationalScoutScheduleId}`).fadeIn()
        $(`#international-scout-schedule-${internationalScoutScheduleId}`).show()
      }

      let data = { 
        "user_id":this.player_id,
        "page" : 'index',
        "action" : 'show',
        "international_scout_schedule_id": internationalScoutScheduleId,            
      }
      setLogs(data)
    }

}