import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect(){
    $('#pitcher_video_form').on('submit',function(event){
      var isReturn=true
      $('.pitcher-video-contents').each(function(index){
        var current_player = $('.player-select').eq(index).val()
        var current_team = $('.team-select').eq(index).val()
        var windupStretch = $('.windup-stretch-select ').eq(index).val()
        var pitchType = $('.pitch-type-select').eq(index).val()
        
        if (current_player=="" | current_team==""){
          if (current_player==""){
            alert(`${index+1}번 선수의 이름을 확인해주세요.`)
            event.preventDefault();
          }
          else if(current_team==""){
            alert(`${index+1}번 선수의 팀을 입력해주세요.`)
            event.preventDefault();
          }
          else if(windupStretch==""){
            alert(`${index+1}번 선수의 투구유형을 입력해주세요.`)
            event.preventDefault();
          }
          else if(pitchType==""){
            alert(`${index+1}번 선수의 구종을 입력해주세요.`)
            event.preventDefault();
          }

          isReturn=false
        }else{
          $(this).attr('disabled', 'disabled');
          $('#international_scout_video_form').addClass('cursor-wait');
          $('#international_scout_video_form').addClass('animate-pulse');
          $('#international_scout_video_form').removeClass('hover:bg-blue-500');
          $(this).val('업로드중...');
        }
      });
      return isReturn
    })    
  }

  getKBOGames(){
    var date = $('#game-date').val()
    this.fetchData(date)
  }
  
  fetchData(date) {
    var gameSelect = $('#pitcher_video_game_id')

    $.ajax({
      url: `/analytics/pitcher_videos/getKBOGames?game_date=${date}&game_year=${date.split('-')[0]}`,
      method: 'get',
      async: false,
      success: function (data){
        $(gameSelect).empty()
        for (let item of data['games']) {
          let option = `<option value=${item['game_id']}>(${item['level']} ${item['game_type']}) ${item['away_team']} vs ${item['home_team']} - ${item['game_number']}차전</option>`;
          $(gameSelect).append(option)
        }
      },
    });
  }
 
  add_video_upload(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_VIDEO/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
    $('.player-select').select2({
      width: '100%',
      templateResult: formatRepo,
      templateSelection: formatRepoSelection,
      ajax: {
        url: '/analytics/pitcher_videos/getPitchers',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          var query = {
              search: params.term
          }
          return query;
        },
        processResults: function (data) {
          return {
            results: data.players,
          };
        },
        cache: true
      },
    });
  }

  remove_video_upload(event) {
    const wrapper = event.target.closest(".pitcher-video")
    if (wrapper.dataset.newRecord == "true") {
        wrapper.remove()
    }
    else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = "none"
    }
  }

  upload_trigger(event){
    var videoList = event.target.closest('.pitcher-video');
    var multiUploadInput = videoList.querySelector("#multi-upload-input-temp");
    multiUploadInput.click(); // this will trigger the click event
  }

  removeMultiUpload(event) {
    var div_videoUpload = event.target.closest('.pitcher-video');
    var div_videoList = event.target.closest('#upload_video_list');
    var eachFile = event.target.closest("div")
    var filename = eachFile.getAttribute('filename')
    
    var multiUploadFiles = div_videoUpload.querySelector("#multi-upload-input").files;
    var fileBuffer = new DataTransfer();

    Object.keys(multiUploadFiles).forEach(function (key, index) {
      let file = multiUploadFiles[key];
      if (file.name!=filename){
        fileBuffer.items.add(file);
      }
    })
    div_videoUpload.querySelector("#multi-upload-input").files = fileBuffer.files;
    div_videoList.removeChild(eachFile);
  }

  displayFile(event){
    var div_videoUpload = event.target.closest('.pitcher-video');
    var multiUploadInput = div_videoUpload.querySelector("#multi-upload-input");
    var multiUploadInputTemp = div_videoUpload.querySelector("#multi-upload-input-temp");
    var div_uploadVideoList  = div_videoUpload.querySelector("#upload_video_list");
    var multiUploadFiles = multiUploadInput.files;
    var multiUploadTempFiles = multiUploadInputTemp.files;

    var existsFileList = []
    var fileBuffer = new DataTransfer();
    Object.keys(multiUploadFiles).forEach(function (key, index) {
      let file = multiUploadFiles[key];
      fileBuffer.items.add(file);
      existsFileList.push(file.name)
    })

    Object.keys(multiUploadTempFiles).forEach(function (key, index) {
      let file = multiUploadTempFiles[key];
      if (existsFileList.includes(file.name)==false){
        fileBuffer.items.add(file);
      }
    })

    multiUploadInput.files = fileBuffer.files;

    if (multiUploadInput.files.length>0) {

      div_uploadVideoList.innerHTML=null

      let files = multiUploadInput.files;
      Object.keys(files).forEach(function (key, index) {
        let file = files[key];
        var strHTML = `
                      <div id='video_file_${index}' filename='${file.name}' class="border border-gray-300 flex items-center">
                        <span id="multi-upload-text" class="px-2 text-lg">${file.name}</span>
                        <button id="multi-upload-delete" class="grid place-items-center" type='button' data-action='click->pitcher-video#removeMultiUpload'>
                          <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-red-700 w-5 h-5"
                                viewBox="0 0 320 512">
                              <path
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                          </svg>
                        </button>
                      </div>
                      `
        div_uploadVideoList.insertAdjacentHTML('beforeend', strHTML );
      })
    }
  }

  // edit
  edit_trigger(event){
    var editUploadInput = document.getElementById("edit-upload-input");
    editUploadInput.click(); // this will trigger the click event
  }

  displayChangedFile(event){
    var changedFileInput = document.getElementById("edit-upload-input");
    var divChangedFiletext = document.getElementById("edit-upload-text");
    var deleteButton = document.getElementById("edit-upload-delete");
    var changedFile = changedFileInput.files;

    var fileBuffer = new DataTransfer();
    Object.keys(changedFile).forEach(function (key, index) {
      let file = changedFile[key];
      fileBuffer.items.add(file);
    })
    changedFileInput.files = fileBuffer.files;

    if (changedFileInput.files.length>0) {
      divChangedFiletext.innerHTML=changedFileInput.files[0].name
      deleteButton.classList.remove("hidden");
    }
  }

  removeChangedFile(event){
    var divChangedFiletext = document.getElementById("edit-upload-text");
    var deleteButton = document.getElementById("edit-upload-delete");

    divChangedFiletext.innerHTML=null
    document.getElementById("edit-upload-input").value = null;
    deleteButton.classList.add("hidden");
    
  }

  // Show Video
  showVideo(event){
    const eachPitcherVideoId = event.target.getAttribute("eachPitcherVideoId")

    if ($(`#each-pitcher-video-${eachPitcherVideoId}`).is(":visible")==true){
      $(`#each-pitcher-video-${eachPitcherVideoId}`).fadeOut()
      $(`#each-pitcher-video-${eachPitcherVideoId}`).hide() 
    }
    else{
      $(`#each-pitcher-video-${eachPitcherVideoId}`).fadeIn()
      $(`#each-pitcher-video-${eachPitcherVideoId}`).show()
    }

    let data = { 
        "user_id":this.player_id,
        "page" : 'index',
        "action" : 'show',
        "each_pitcher_video_id": eachPitcherVideoId,            
    }
    setLogs(data)
  }

}

function setLogs(data){
  $.ajax({
      url: '/analytics/pitcher_videos/setLogs',
      method: 'GET',
      async: false,
      data: data
  });        
}

function formatRepo (repo) {
  if (repo.loading) {
    return repo.text;
  }

  var $container = $(

    "<div class='flex items-center gap-1 p-1'>"+
      "<img class='w-16 h-16' src='" + repo.thumnail + "'>"+
      "<div class='flex flex-col'>"+
          '<strong class="text-slate-900 text-sm font-medium dark:text-slate-200 select2-result-repository__title"></strong>'+
          '<span class="text-slate-500 text-sm font-medium dark:text-slate-400 select2-result-repository__description"></span>'+
      "</div>"+
    "</div>"
  );

  $container.find(".select2-result-repository__title").text(repo.title);
  $container.find(".select2-result-repository__description").text(repo.text);

  return $container;
}

function formatRepoSelection (repo) {
  return repo.full_name || repo.title;
}