import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {

    timeout = null;

    button = document.getElementById('navbar-toggle');
    menu = document.getElementById('navbar-dropdown');
  
    dropdownButton = document.getElementById('dropdownNavbarLink');
    dropdownMenu = document.getElementById('dropdownNavbar');

    inputPlayers = document.getElementById('playerSearch');
  
    menuAnalytics = document.getElementById('menuAnaltyics')
    menuScouts = document.getElementById('menuScouts')
    menuPlayerDevelopment = document.getElementById('menuPlayerDevelopment')
    menuSportsScience = document.getElementById('menuSportsScience')
    menuRookie = document.getElementById('menuRookie')
    menuInternational = document.getElementById('menuInternational')

    dropdownAnalytics = document.getElementById('dropdownAnalytics')
    dropdownScouts = document.getElementById('dropdownScouts')
    dropdownPlayerDevelopment = document.getElementById('dropdownPlayerDevelopment')
    dropdownSportsScience = document.getElementById('dropdownSportsScience')
    dropdownRookie = document.getElementById('dropdownRookie')
    dropdownInternational = document.getElementById('dropdownInternational')

    connect(){
        $(window).on('resize', function(){
            let playerResultWidth = null;
            let inputMobileElement = document.getElementById('mobileSearch')
            let inputDesktopElement = document.getElementById('desktopSearch')
            // mobile
            if (document.body.clientWidth<768){
                playerResultWidth=inputMobileElement.clientWidth
            } else{
                playerResultWidth=inputDesktopElement.clientWidth
            }

            $('.playerResult > ul').each(function(){
              $(this).width(playerResultWidth)
            })
        });

        $(window).on('click', function(event){
            if ((event.target.id!='playerSearch') & ($('#playerResultSelect').hasClass('hidden')==false)){
                $('#playerResultSelect > ul').each(function(){
                    $(this).addClass('hidden')
                })
            }
        })

        // this.inputPlayers.addEventListener('focusout',()=>{
        //     $('#playerResultSelect > ul').each(function(){
        //         $(this).addClass('hidden')
        //     })            
        // })

        this.inputPlayers.addEventListener('focusin',()=>{
            $('#playerResultSelect > ul').each(function(){
                $(this).removeClass('hidden')
            })            
        })

    }

    setDropdownButton(){
        let dropdownMenu = document.getElementById('dropdownNavbar');
        dropdownMenu.classList.toggle('hidden');
    }

    setButton(){
        let menu = document.getElementById('navbar-dropdown');
        menu.classList.toggle('hidden');
    }
    
    searchPlayers(event){
        const searchName = event.target.value;
        let inputMobileElement = document.getElementById('mobileSearch')
        let inputDesktopElement = document.getElementById('desktopSearch')

        if (inputMobileElement.style.display==true){
            inputMobileElement.value = searchName
        } else{
            inputDesktopElement.value = searchName
        }

        clearTimeout(this.timeout);
        this.timeout=setTimeout(function(){
            $.ajax({
                url: `/search.json?q=${searchName}`,
                method: 'get',
                async: false,
                success: function (data){
                    let searchResultElements = document.getElementsByClassName('playerResult')

                    let playerResultWidth = 0;
                    // mobile
                    if (document.body.clientWidth<768){
                        playerResultWidth=inputMobileElement.clientWidth
                    } else{
                        playerResultWidth=inputDesktopElement.clientWidth
                    }
                    
                    var strHTML = `<ul class='border overflow-y-auto h-40 overflow-y: scroll; bg-white z-50' style='width:${playerResultWidth}px'>`;
                    
                    for (let element of searchResultElements) {
                        element.innerHTML=null;
                    }
                    
                    // searchResultElement.innerHTML=null;
                    let players = data['players']
                    // Batter Throws Split Table
                    if (players.length>0){
                        for(let [ index, player ] of players.entries()) {
                            strHTML += `
                                <li class='hover:bg-gray-300'>
                                    <a href="${player['url']}" class="w-full bg-white player-link" aria-current="page">
                                        <div class="flex items-center gap-4 p-4">
                                            <img class="w-16 h-16" src="${player['player_img']}">
                                            <div class="flex flex-col">
                                                <strong class="text-slate-900 text-sm font-medium dark:text-slate-200">${player['name']}(${player['birth_date']}) | ${player['position']}</strong>
                                                <span class="text-slate-500 text-sm font-medium dark:text-slate-400">${player['team_name']}</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            `
                        }
                        strHTML+='</ul>';

                        for (let element of searchResultElements) {
                            element.insertAdjacentHTML( 'beforeend', strHTML );
                        }

                        // searchResultElement.insertAdjacentHTML( 'beforeend', strHTML );
                    }else{
                        let strHTML = `<ul class='border overflow-y: scroll; bg-white' style='width:${playerResultWidth}px'></ul>`
                        for (let element of searchResultElements) {
                            element.insertAdjacentHTML( 'beforeend', strHTML );
                        }
                    }
                
                },
            });
        },50);
    }

    openAnalytics(event){
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'block';
        $('#buttonOpenAnalytics').removeClass('text-gray-300')
        $('#buttonOpenAnalytics').addClass('bg-white text-black')
        // $('#menuAnaltyics').removeClass('hidden')

        // $('#buttonOpenAnalytics').removeClass('text-gray-300')
        // $('#buttonOpenAnalytics').addClass('bg-white text-black')
        // if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';

        $('#buttonOpenScouts').removeClass('bg-white text-black')
        $('#buttonOpenScouts').addClass('text-gray-300')
        if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        $('#buttonOpenSportsScience').addClass('text-gray-300')
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        $('#buttonOpenRookie').removeClass('bg-white text-black')
        $('#buttonOpenRookie').addClass('text-gray-300')
        if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        $('#buttonOpenInternational').removeClass('bg-white text-black')
        $('#buttonOpenInternational').addClass('text-gray-300')
        if(this.menuInternational!=null) this.menuInternational.style.display = 'none';


        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    closeAnalytics(event){
        $('#buttonOpenAnalytics').removeClass('bg-white text-black')
        $('#buttonOpenAnalytics').addClass('text-gray-300')
        // $('#menuAnaltyics').addClass('hidden')
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';
    }

    openScouts(event){
        if(this.menuScouts!=null) this.menuScouts.style.display = 'block';
        $('#buttonOpenScouts').addClass('bg-white text-black')
        $('#buttonOpenScouts').removeClass('text-gray-300')
        // $('#menuScouts').removeClass('hidden')

        $('#buttonOpenAnalytics').removeClass('bg-white text-black')
        $('#buttonOpenAnalytics').addClass('text-gray-300')
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';

        // $('#buttonOpenScouts').removeClass('bg-white text-black')
        // $('#buttonOpenScouts').addClass('text-gray-300')
        // if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        $('#buttonOpenSportsScience').addClass('text-gray-300')
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        $('#buttonOpenRookie').removeClass('bg-white text-black')
        $('#buttonOpenRookie').addClass('text-gray-300')
        if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        $('#buttonOpenInternational').removeClass('bg-white text-black')
        $('#buttonOpenInternational').addClass('text-gray-300')
        if(this.menuInternational!=null) this.menuInternational.style.display = 'none';


        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    closeScouts(event){

        $('#buttonOpenScouts').removeClass('bg-white text-black')
        $('#buttonOpenScouts').addClass('text-gray-300')
        if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        // if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';
        // // if(this.menuScouts!=null) this.menuScouts.style.display = 'none';
        // if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';
        // if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';
        // if(this.menuRookie!=null) this.menuRookie.style.display = 'none';
        // if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    openPlayerDevelopment(event){
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'block';
        $('#buttonOpenPlayerDevelopment').addClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').removeClass('text-gray-300')
        // $('#menuScouts').removeClass('hidden')

        $('#buttonOpenAnalytics').removeClass('bg-white text-black')
        $('#buttonOpenAnalytics').addClass('text-gray-300')
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';

        $('#buttonOpenScouts').removeClass('bg-white text-black')
        $('#buttonOpenScouts').addClass('text-gray-300')
        if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        // $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        // $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        // if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        $('#buttonOpenSportsScience').addClass('text-gray-300')
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        $('#buttonOpenRookie').removeClass('bg-white text-black')
        $('#buttonOpenRookie').addClass('text-gray-300')
        if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        $('#buttonOpenInternational').removeClass('bg-white text-black')
        $('#buttonOpenInternational').addClass('text-gray-300')
        if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    closePlayerDevelopment(event){
        $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        // if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';
        // // if(this.menuScouts!=null) this.menuScouts.style.display = 'none';
        // if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';
        // if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';
        // if(this.menuRookie!=null) this.menuRookie.style.display = 'none';
        // if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    openSportsScience(event){
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'block';
        $('#buttonOpenSportsScience').addClass('bg-white text-black')
        $('#buttonOpenSportsScience').removeClass('text-gray-300')
        // $('#menuScouts').removeClass('hidden')

        $('#buttonOpenAnalytics').removeClass('bg-white text-black')
        $('#buttonOpenAnalytics').addClass('text-gray-300')
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';

        $('#buttonOpenScouts').removeClass('bg-white text-black')
        $('#buttonOpenScouts').addClass('text-gray-300')
        if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        // $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        // $('#buttonOpenSportsScience').addClass('text-gray-300')
        // if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        $('#buttonOpenRookie').removeClass('bg-white text-black')
        $('#buttonOpenRookie').addClass('text-gray-300')
        if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        $('#buttonOpenInternational').removeClass('bg-white text-black')
        $('#buttonOpenInternational').addClass('text-gray-300')
        if(this.menuInternational!=null) this.menuInternational.style.display = 'none';


        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    closeSportsScience(event){
        $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        $('#buttonOpenSportsScience').addClass('text-gray-300')
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        // if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';
        // // if(this.menuScouts!=null) this.menuScouts.style.display = 'none';
        // if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';
        // if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';
        // if(this.menuRookie!=null) this.menuRookie.style.display = 'none';
        // if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    openRookie(event){
        if(this.menuRookie!=null) this.menuRookie.style.display = 'block';
        $('#buttonOpenRookie').addClass('bg-white text-black')
        $('#buttonOpenRookie').removeClass('text-gray-300')
        // $('#menuScouts').removeClass('hidden')

        $('#buttonOpenAnalytics').removeClass('bg-white text-black')
        $('#buttonOpenAnalytics').addClass('text-gray-300')
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';

        $('#buttonOpenScouts').removeClass('bg-white text-black')
        $('#buttonOpenScouts').addClass('text-gray-300')
        if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        $('#buttonOpenSportsScience').addClass('text-gray-300')
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        // $('#buttonOpenRookie').removeClass('bg-white text-black')
        // $('#buttonOpenRookie').addClass('text-gray-300')
        // if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        $('#buttonOpenInternational').removeClass('bg-white text-black')
        $('#buttonOpenInternational').addClass('text-gray-300')
        if(this.menuInternational!=null) this.menuInternational.style.display = 'none';


        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    closeRookie(event){
        $('#buttonOpenRookie').removeClass('bg-white text-black')
        $('#buttonOpenRookie').addClass('text-gray-300')
        if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        // if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';
        // // if(this.menuScouts!=null) this.menuScouts.style.display = 'none';
        // if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';
        // if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';
        // if(this.menuRookie!=null) this.menuRookie.style.display = 'none';
        // if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    openInternational(event){
        if(this.menuInternational!=null) this.menuInternational.style.display = 'block';
        $('#buttonOpenInternational').addClass('bg-white text-black')
        $('#buttonOpenInternational').removeClass('text-gray-300')
        // $('#menuScouts').removeClass('hidden')

        $('#buttonOpenAnalytics').removeClass('bg-white text-black')
        $('#buttonOpenAnalytics').addClass('text-gray-300')
        if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';

        $('#buttonOpenScouts').removeClass('bg-white text-black')
        $('#buttonOpenScouts').addClass('text-gray-300')
        if(this.menuScouts!=null) this.menuScouts.style.display = 'none';

        $('#buttonOpenPlayerDevelopment').removeClass('bg-white text-black')
        $('#buttonOpenPlayerDevelopment').addClass('text-gray-300')
        if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';

        $('#buttonOpenSportsScience').removeClass('bg-white text-black')
        $('#buttonOpenSportsScience').addClass('text-gray-300')
        if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';

        $('#buttonOpenRookie').removeClass('bg-white text-black')
        $('#buttonOpenRookie').addClass('text-gray-300')
        if(this.menuRookie!=null) this.menuRookie.style.display = 'none';

        // $('#buttonOpenInternational').removeClass('bg-white text-black')
        // $('#buttonOpenInternational').addClass('text-gray-300')
        // if(this.menuInternational!=null) this.menuInternational.style.display = 'none';


        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    closeInternational(event){
        $('#buttonOpenInternational').removeClass('bg-white text-black')
        $('#buttonOpenInternational').addClass('text-gray-300')
        if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // if(this.menuAnalytics!=null) this.menuAnalytics.style.display = 'none';
        // // if(this.menuScouts!=null) this.menuScouts.style.display = 'none';
        // if(this.menuPlayerDevelopment!=null) this.menuPlayerDevelopment.style.display = 'none';
        // if(this.menuSportsScience!=null) this.menuSportsScience.style.display = 'none';
        // if(this.menuRookie!=null) this.menuRookie.style.display = 'none';
        // if(this.menuInternational!=null) this.menuInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }


    // dropdown
    setDropdownAnalytics(){
        if($('#dropdownAnalytics').hasClass('hidden')==true){
            if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'block';
            $('#dropdownAnalytics').removeClass('hidden')
        }else{
            if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
            $('#dropdownAnalytics').addClass('hidden')
        }

        // if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
        if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
        if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
        if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
        if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
        if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    setDropdownScouts(){
        if($('#dropdownScouts').hasClass('hidden')==true){
            if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'block';
            $('#dropdownScouts').removeClass('hidden')
        }else{
            if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
            $('#dropdownScouts').addClass('hidden')
        }

        if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
        // if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
        if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
        if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
        if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
        if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    setDropdownPlayerDevelopment(){
        if($('#dropdownPlayerDevelopment').hasClass('hidden')==true){
            if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'block';
            $('#dropdownPlayerDevelopment').removeClass('hidden')
        }else{
            if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
            $('#dropdownPlayerDevelopment').addClass('hidden')
        }

        if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
        if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
        // if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
        if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
        if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
        if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    setDropdownSportsScience(){
        if($('#dropdownSportsScience').hasClass('hidden')==true){
            if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'block';
            $('#dropdownSportsScience').removeClass('hidden')
        }else{
            if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
            $('#dropdownSportsScience').addClass('hidden')
        }

        if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
        if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
        if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
        // if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
        if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
        if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    setDropdownRookie(){
        if($('#dropdownRookie').hasClass('hidden')==true){
            if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'block';
            $('#dropdownRookie').removeClass('hidden')
        }else{
            if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
            $('#dropdownRookie').addClass('hidden')
        }

        if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
        if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
        if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
        if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
        // if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
        if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

    setDropdownInternational(){
        if($('#dropdownInternational').hasClass('hidden')==true){
            if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'block';
            $('#dropdownInternational').removeClass('hidden')
        }else{
            if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';
            $('#dropdownInternational').addClass('hidden')
        }

        if(this.dropdownAnalytics!=null) this.dropdownAnalytics.style.display = 'none';
        if(this.dropdownScouts!=null) this.dropdownScouts.style.display = 'none';
        if(this.dropdownPlayerDevelopment!=null) this.dropdownPlayerDevelopment.style.display = 'none';
        if(this.dropdownSportsScience!=null) this.dropdownSportsScience.style.display = 'none';
        if(this.dropdownRookie!=null) this.dropdownRookie.style.display = 'none';
        // if(this.dropdownInternational!=null) this.dropdownInternational.style.display = 'none';

        // let data = { 
        //     "player_id":this.player_id,
        //     "kind" : 'show',
        //     "sector": 'record',
        //     "tab": 'milb',
        //     'type':'game_logs'
        // }
        // setLogs(data)
    }

}