import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "template" ]

    initialize(){
        $('.player-select').select2({
            width: '100%',
            templateResult: formatRepo,
            templateSelection: formatRepoSelection,
            ajax: {
                url: '/scouts/second_draft_pitcher_reports/getPlayers',
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    var query = {
                        search: params.term
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                    results: data.players,
                    };
                },
                cache: true
            },
        });
        $('.report-content').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
    }

    connect() {
        $(window).on('resize', function(){
            $('.report-content').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
        });
    }
    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }
}

function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }
  
    var $container = $(
  
      "<div class='flex items-center gap-1 p-1'>"+
        "<img class='w-16 h-16' src='" + repo.thumnail + "'>"+
        "<div class='flex flex-col'>"+
            '<strong class="text-slate-900 text-sm font-medium dark:text-slate-200 select2-result-repository__title"></strong>'+
            '<span class="text-slate-500 text-sm font-medium dark:text-slate-400 select2-result-repository__description"></span>'+
        "</div>"+
      "</div>"
    );
  
    $container.find(".select2-result-repository__title").text(repo.title);
    $container.find(".select2-result-repository__description").text(repo.text);
  
    return $container;
}

function formatRepoSelection (repo) {
    return repo.full_name || repo.title;
}