import { Controller } from "stimulus"

export default class extends Controller {
    initialize(){
        $('.player-select').select2({
            width: '100%',
        });
        $('.date-select').select2({
            placeholder: "날짜 선택",
            width: '100%',
        });
        $('.pitch-select').select2({
            placeholder: "투구 선택",
            width: '100%',
        });
    }

    connect(){
        $('.player-select').on("select2:select", function(event)  {
            const wrapper = event.target.closest(".pitching-sector");
            var playerID = $(event.target).find(':selected').val();
            fetch(`getDates?player_id=${playerID}`)
            .then(resp => resp.json())
            .then(data => {
            setDates(wrapper, data);
            })
        })
        $('.date-select').on("select2:select", function(event)  {
            const wrapper = event.target.closest(".pitching-sector");
            var playerID = wrapper.querySelector('.player-select').value
            var date = $(event.target).find(':selected').val();
            fetch(`getPitches?date=${date}&player_id=${playerID}`)
            .then(resp => resp.json())
            .then(data => {
            setPitches(wrapper, data);
            })
        })
        $('.pitch-select').on("select2:select", function(event)  {
            const wrapper = event.target.closest(".pitching-sector");
            var pitchUID = $(event.target).find(':selected').val();
            fetch(`getPitchData?pitch_uid=${pitchUID}`)
            .then(resp => resp.json())
            .then(data => {
            setPitchData(wrapper, data);
            })
        })
    }
}

function setDates(wrapper, dateList){
    let dateSelect = wrapper.querySelector('.date-select')
    dateSelect.innerHTML = ''
    let pitchSelect = wrapper.querySelector('.pitch-select')
    pitchSelect.innerHTML = ''
    var placeHolder = document.createElement('option')
    placeHolder.value = null;
    placeHolder.innerHTML = '날짜 선택';
    dateSelect.append(placeHolder)
    for (let item of dateList['pitchDates']) {
  
        var opt = document.createElement('option');
        opt.value = item;
        opt.innerHTML = item;
        dateSelect.appendChild(opt)
    }
}

function setPitches(wrapper, pitchList){
    let pitchSelect = wrapper.querySelector('.pitch-select')
    pitchSelect.innerHTML = ''
    var placeHolder = document.createElement('option')
    placeHolder.value = null;
    placeHolder.innerHTML = '투구 선택';
    pitchSelect.append(placeHolder)
    for (let [index, item] of pitchList['pitches'].entries()) {
        var opt = document.createElement('option');
        opt.value = item["pitch_uid"];
        opt.innerHTML = `${index+1}구 - ${item['pitch_type']}`;
        pitchSelect.appendChild(opt)
    }
}

function setPitchData(wrapper, pitchData){
    let pitch = pitchData['pitchData']
    let pitchType = wrapper.querySelector('.pitch-type')
    // pitchType.innerHTML = pitch['pitch_type']
    pitchType.innerHTML = pitch['pitch_type']

    let relSpeed = wrapper.querySelector('.rel-speed')
    // relSpeed.innerHTML = pitch['rel_speed']
    relSpeed.innerHTML = pitch['rel_speed']==null ?  '--' : `${pitch['rel_speed'].toFixed(1)}km/h`

    let spinRate = wrapper.querySelector('.spinrate')
    // spinRate.innerHTML = pitch['spin_rate']
    spinRate.innerHTML = pitch['spin_rate']==null ?  '--' : `${pitch['spin_rate'].toFixed(0)}RPM`

    let relHeight = wrapper.querySelector('.rel-height')
    // relHeight.innerHTML = pitch['rel_height']
    relHeight.innerHTML = pitch['rel_height']==null ?  '--' : `${pitch['rel_height'].toFixed(2)}m`

    let relSide = wrapper.querySelector('.rel-side')
    // relSide.innerHTML = pitch['rel_side']
    relSide.innerHTML = pitch['rel_side']==null ?  '--' : `${pitch['rel_side'].toFixed(2)}m`

    let extension = wrapper.querySelector('.extension')
    // extension.innerHTML = pitch['extension']
    extension.innerHTML = pitch['extension']==null ?  '--' : `${pitch['extension'].toFixed(2)}m`

    let vertBreak = wrapper.querySelector('.vert-break')
    // vertBreak.innerHTML = pitch['induced_vert_break']
    vertBreak.innerHTML = pitch['induced_vert_break']==null ?  '--' : `${pitch['induced_vert_break'].toFixed(1)}cm`

    let horzBreak = wrapper.querySelector('.horz-break')
    // horzBreak.innerHTML = pitch['horz_break']
    horzBreak.innerHTML = pitch['horz_break']==null ?  '--' : `${pitch['horz_break'].toFixed(1)}cm`

    let videoDiv = wrapper.querySelector('.portable-trackman-video')
    videoDiv.innerHTML=null
    if(pitch['video_path']!=null){

        let video = document.createElement('video')
        video.setAttribute('playsinline',null)
        video.setAttribute('controls',null)
        video.setAttribute('controlsList','nodownload')
    
        let videoSrc = `<source src='${pitch['video_path']}' type='video/mp4'/>`
        video.innerHTML = videoSrc

        videoDiv.append(video)
        
    } else {
        let alertHTML = `
            <div class='m-auto'>
                <div class='flex items-center justify-center p-2'>
                    <span class="text-xl sm:text-md font-bold text-gray-700">영상이 없습니다.</span>
                </div>
            </div>        
        `
        videoDiv.innerHTML=alertHTML
    }
}
